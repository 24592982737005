export const mapStyles = [
    {
        "elementType": "geometry",
        "stylers": [
            { "color": "#1d2c4d" } // Dark, immersive base background
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            { "color": "#00ffa6" } // Vibrant neon green for text
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            { "color": "#000000" } // Black outline for glowing effect
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            { "color": "#ff0000" }, // Red boundaries for emphasis
            { "weight": 1.2 } // Thicker strokes for visibility
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [
            { "color": "#08427b" } // Rich blue for natural landscapes
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            { "color": "#ff8303" } // Bright orange for POIs
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            { "color": "#46eaff" } // Soft aqua-blue for POI labels
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            { "color": "#005f73" } // Teal-green parks for variety
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            { "color": "#2b6f8e" } // Highlighted roads with teal-blue tone
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            { "color": "#00ffc6" } // Neon teal for road labels
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            { "color": "#8a2be2" }, // Electric purple for highways
            { "weight": 1.5 } // Slightly thicker for highways
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            { "color": "#e6e6fa" } // Light lavender for highway text
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            { "color": "#9b870c" } // Gold for transit lines
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            { "color": "#ff4500" } // Bright red for stations
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            { "color": "#001a33" } // Deep blue water for intensity
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            { "color": "#00bfff" } // Neon cyan for water text
        ]
    }
];
