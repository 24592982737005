import React, { useEffect, useRef, useState } from "react";
import anime from "animejs";

const AnimatedCounter = ({ num }) => {
  const counterRef = useRef(null);
  const [isCountingComplete, setIsCountingComplete] = useState(false);

  const [deviceIsComputer, setDeviceIsComputer] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIphone, setIsIphone] = useState(false);
  const [isTablet, setIsTablet] = useState(null);

  // Device-specific style loading
  useEffect(() => {
    const loadStyles = async () => {
      const userAgent = navigator.userAgent.toLowerCase();

      try {
        if (userAgent.includes("iphone")) {
          await import("./iphoneStyles.css");
          setIsIphone(true);
          console.log("iPhone styles loaded");
        } else if (userAgent.includes("ipad") || userAgent.includes("tablet")) {
          await import("./tabletStyles.css");
          console.log("Tablet styles loaded");
          setIsTablet(true);
        } else if (userAgent.includes("win") || userAgent.includes("mac")) {
          await import("./stylesServiceSpotter.css");
          console.log("Windows or macOS styles loaded");
          setDeviceIsComputer(true);
        } else {
          await import("./androidIos.css");
          setIsAndroid(true);
          console.log("Device-specific styles loaded");
        }
      } catch (error) {
        console.error("Error loading styles:", error);
      }
    };

    loadStyles();
  }, []);

  // Animate the counter
  useEffect(() => {
    if (counterRef.current) {
      anime({
        targets: counterRef.current,
        innerHTML: [0, num],
        easing: "easeOutExpo",
        round: 1,
        duration: 3000,
        complete: () => {
          // Mark counting as complete
          setIsCountingComplete(true);
        },
      });
    }
  }, [num]);

  return (
    <div
      className={`btn btn-dark btn-sm ${
        isCountingComplete ? "fade-out-in" : ""
      }`}
      id="counterCard"
    >
      <p id="label">Service Providers</p>
      <p>
        <span ref={counterRef} id="labelNumCounter">0</span>
      </p>
    </div>
  );
};

export default AnimatedCounter;
