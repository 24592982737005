import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { db, storage } from './firebase';
import { collection, addDoc, getDocs, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { Autocomplete } from '@react-google-maps/api';

const ServiceProvidersTable = () => {
  const [providers, setProviders] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [tempProvider, setTempProvider] = useState({});
  const [newProvider, setNewProvider] = useState({
    name: '',
    email: '',
    website: '',
    qualification: '',
    service: '',
    phoneNumber: '',
    address: '',
    location: {},
    imageUrls: [],
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageNames, setSelectedImageNames] = useState([]);
  const addressRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const qualifications = [
    "All",
    "Aluminium",
    "Appliance Repair",
    "Baking",
    "Carpentry",
    "Carport",
    //"Catering",
    "Cleaning Services (Home/Office)",
    "Construction",
    "Electrician",
    "Gate Manufacturer",
    "General Handyman",
    "HVAC (Heating, Ventilation, and Air Conditioning)",
    "Landscaping and Gardening",
    "Locksmithing",
    "Masonry",
    "Mechanic",
    "Painting and Decorating",
    "Pest Control",
    "Paving",
    "Paving and Driveway Maintenance",
    "Plumbing",
    "Radiator Mechanic",
    "Roofing",
    "Rubble Remover",
    "Security System Installation",
    "Steel Work",
    "Swimming Pool Maintenance",
    "Tiling and Flooring",
    "Upholstery",
    "Waterproofing",
    "Welding",
    "Window and Glass Repair"
  ];

  useEffect(() => {
    const fetchProviders = async () => {
      setLoading(true);
      try {
        const providersCollection = collection(db, 'serviceProviders');
        const providerSnapshot = await getDocs(providersCollection);
        const providerList = providerSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProviders(providerList);
      } catch (error) {
        console.error("Error fetching providers: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProviders();
  }, []);

  const handleEditClick = (index) => {
    setEditIndex(index);
    setTempProvider({ ...providers[index] });
    setSelectedImages([]);
    setSelectedImageNames([]);
  };

  const handleDeleteConfirm = async () => {
    const providerDoc = doc(db, 'serviceProviders', providers[deleteIndex].id);
    await deleteDoc(providerDoc);

    const updatedProviders = providers.filter((_, i) => i !== deleteIndex);
    setProviders(updatedProviders);
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setTempProvider({ ...tempProvider, [name]: value });

  };

  const handleInputPlaceChanged = () => {
    if (addressRef.current) {
      const place = addressRef.current.getPlace();

      // Extract the formatted address
      const formattedAddress = place.formatted_address;

      // Extract latitude and longitude from the place object
      const location = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      // Update the state with the new address and location
      setTempProvider({
        ...tempProvider,
        address: formattedAddress,
        location, // Update the location field
      });
    }
  };



  const handleSaveClick = async () => {
    const providerDoc = doc(db, 'serviceProviders', providers[editIndex].id);

    let imageUrls = [...tempProvider.imageUrls]; // Start with the existing images
    if (selectedImages.length > 0) {
      // Upload new images if any
      for (let image of selectedImages) {
        const imageUrl = await uploadImageAsBytes(image);
        imageUrls.push(imageUrl);
      }
    }

    await updateDoc(providerDoc, { ...tempProvider, imageUrls });

    const updatedProviders = [...providers];
    updatedProviders[editIndex] = { ...tempProvider, imageUrls };
    setProviders(updatedProviders);
    setEditIndex(-1);
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewProvider({ ...newProvider, [name]: value });
  };

  const handleAddressChange = (address) => {
    setNewProvider({ ...newProvider, address });
  };

  const handlePlaceChanged = () => {
    const place = addressRef.current.getPlace();
    if (place && place.formatted_address) {
      handleAddressChange(place.formatted_address);
    } else {
      console.error("No valid place selected");
    }
  };

  const getLatLngFromAddress = async (address) => {
    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          const { lat, lng } = results[0].geometry.location;
          resolve({ lat: lat(), lng: lng() });
        } else {
          reject('Geocode was not successful for the following reason: ' + status);
        }
      });
    });
  };

  const uploadImageAsBytes = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `images/${file.name}`);
      const reader = new FileReader();

      reader.onload = async (event) => {
        const blob = new Blob([new Uint8Array(event.target.result)], { type: file.type });
        try {
          await uploadBytes(storageRef, blob);
          const downloadURL = await getDownloadURL(storageRef);
          resolve(downloadURL);
        } catch (error) {
          console.error("Image upload failed:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false);
  };

  const handleAddProvider = async () => {
    if (!newProvider.name || !newProvider.qualification || !newProvider.service || !newProvider.phoneNumber || !newProvider.address) {
      alert('Please fill in all fields.');
      return;
    }

    try {
      const location = await getLatLngFromAddress(newProvider.address);
      let imageUrls = [];

      if (selectedImages.length > 0) {
        for (let image of selectedImages) {
          const imageUrl = await uploadImageAsBytes(image);
          imageUrls.push(imageUrl);
        }
      }

      const providerToAdd = await addDoc(collection(db, 'serviceProviders'), { ...newProvider, location, imageUrls });
      setProviders([...providers, { id: providerToAdd.id, ...newProvider, location, imageUrls }]);
      setNewProvider({ name: '', qualification: '', service: '', phoneNumber: '', address: '', location: {}, imageUrls: [] });
      setSelectedImages([]);
      setSelectedImageNames([]);
    } catch (error) {
      console.error(error);
      alert('Failed to get location or upload image.');
    }
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const currentSelectedCount = selectedImages.length;

    if (currentSelectedCount + files.length > 6) {
      alert('You can only add up to 6 images.');
      return;
    }

    const newSelectedImages = files.slice(0, 6 - currentSelectedCount);
    setSelectedImages((prev) => [...prev, ...newSelectedImages]);
    setSelectedImageNames((prev) => [...prev, ...newSelectedImages.map(file => file.name)]);
  };

  const handleImageRemove = (imgIndex) => {
    const updatedImages = tempProvider.imageUrls.filter((_, index) => index !== imgIndex);
    setTempProvider({ ...tempProvider, imageUrls: updatedImages });
  };

  return (
    <div>
      {loading ? (
        <Typography variant="h6">Loading...</Typography>
      ) : (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
          <Typography variant="h6" style={{ padding: '20px' }}>Service Providers</Typography>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Profession</TableCell>
                <TableCell>Services</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Website</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Location (Lat, Lng)</TableCell>
                <TableCell>Images</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providers.map((provider, index) => (
                <TableRow key={provider.id}>
                  <TableCell>{editIndex === index ? <TextField style={{ width: '160px' }} name="name" value={tempProvider.name} onChange={handleInputChange} /> : provider.name}</TableCell>


                  <TableCell>
                    {editIndex === index ? (
                      <FormControl fullWidth>
                        <InputLabel>Profession</InputLabel>
                        <Select
                          name="qualification" // This should match the key in tempProvider
                          style={{ width: '160px' }}
                          value={tempProvider.qualification} // Ensure this matches the field in tempProvider
                          onChange={handleInputChange}
                        >
                          {qualifications.map((qualification) => (
                            <MenuItem key={qualification} value={qualification}>
                              {qualification}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      provider.qualification
                    )}
                  </TableCell>

                  <TableCell>{editIndex === index ? <TextField style={{ width: '160px' }} name="service" value={tempProvider.service} onChange={handleInputChange} /> : provider.service}</TableCell>
                  <TableCell>{editIndex === index ? <TextField style={{ width: '160px' }} name="phoneNumber" value={tempProvider.phoneNumber} onChange={handleInputChange} /> : provider.phoneNumber}</TableCell>
                  <TableCell>{editIndex === index ? <TextField style={{ width: '160px' }} name="email" value={tempProvider.email} onChange={handleInputChange} /> : provider.email}</TableCell>
                  <TableCell>{editIndex === index ? <TextField style={{ width: '160px' }} name="website" value={tempProvider.website} onChange={handleInputChange} /> : provider.website}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <div style={{ position: 'relative', zIndex: 100 }}>
                        <Autocomplete
                          onLoad={(autocomplete) => (addressRef.current = autocomplete)}
                          onPlaceChanged={handleInputPlaceChanged}
                          disablePortal
                        >
                          <TextField
                            name="address"
                            style={{ width: '160px' }}
                            value={tempProvider.address}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                          />
                        </Autocomplete>
                      </div>
                    ) : (
                      provider.address
                    )}
                  </TableCell>



                  <TableCell>{editIndex === index ? `${tempProvider.location.lat}, ${tempProvider.location.lng}` : `${provider.location.lat}, ${provider.location.lng}`}</TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <>
                        {tempProvider.imageUrls && tempProvider.imageUrls.length > 0 && (
                          <div>
                            {tempProvider.imageUrls.map((url, imgIndex) => (
                              <div key={imgIndex} style={{ display: 'inline-block', marginRight: '10px' }}>
                                <img
                                  src={url}
                                  alt={`Provider Image ${imgIndex}`}
                                  style={{ width: '50px', height: '50px', marginRight: '5px' }}
                                />
                                <Button onClick={() => handleImageRemove(imgIndex)}>Remove</Button>
                              </div>
                            ))}
                          </div>
                        )}
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleFileSelect}
                        />
                      </>
                    ) : (
                      provider.imageUrls && provider.imageUrls.length > 0 && (
                        <img
                          src={provider.imageUrls[0]}
                          alt={`Provider Image 0`}
                          style={{ width: '50px', height: '50px', marginRight: '5px' }}
                        />
                      )
                    )}
                  </TableCell>
                  <TableCell>
                    {editIndex === index ? (
                      <>
                        <Button onClick={handleSaveClick}>Save</Button>
                        <Button onClick={() => setEditIndex(-1)}>Cancel</Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => handleEditClick(index)}><EditIcon /></Button>
                        <Button onClick={() => handleDeleteClick(index)}><DeleteIcon /></Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box>
        <Typography variant="h6" style={{ marginTop: '20px' }}>Add New Provider</Typography>
        <TextField name="name" label="Name" value={newProvider.name} onChange={handleNewInputChange} fullWidth margin="normal" />

        {/* Qualification Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Profession</InputLabel>
          <Select
            name="qualification"
            value={newProvider.qualification}
            onChange={handleNewInputChange}
          >
            {qualifications.map((qualification) => (
              <MenuItem key={qualification} value={qualification}>
                {qualification}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField name="service" label="service" value={newProvider.service} onChange={handleNewInputChange} fullWidth margin="normal" />
        <TextField name="phoneNumber" label="Phone Number" value={newProvider.phoneNumber} onChange={handleNewInputChange} fullWidth margin="normal" />
        <TextField name="email" label="Email" value={newProvider.email} onChange={handleNewInputChange} fullWidth margin="normal" />
        <TextField name="website" label="Website" value={newProvider.website} onChange={handleNewInputChange} fullWidth margin="normal" />

        <Autocomplete
          onLoad={(autocomplete) => addressRef.current = autocomplete}
          onPlaceChanged={handlePlaceChanged}
        >
          <TextField label="Search for address" fullWidth margin="normal" />
        </Autocomplete>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleFileSelect}
        />
        <Button onClick={handleAddProvider}>Add Provider</Button>
      </Box>

      <Dialog open={openDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this provider?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ServiceProvidersTable;
